import React, { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Grid2,
  LinearProgress,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import APIServerRoot from '../../../modules/UIComponents/ApiEnvironment.jsx';
import { format } from 'date-fns';

const YupValidation = yup.object().shape({
  gitusername: yup
    .string()
    // .min(3, 'Too Short !')
    // .max(30, 'Too Long !')
    .required('Required !'),
});
// Example here https://codesandbox.io/s/simple-mui-formik-yup-form-validation-jlr1o?file=/src/Components/YupValidation.js:0-28
function UserCheckForm({ handleUserData }) {
  const initialValue = {
    gitusername: '',
  };

  const [usererror, setUserError] = useState(false);
  const [userfound, setUserFound] = useState(false);
  const [userindexed, setUserIndexed] = useState(false);
  const [useraddedon, setUserAddedOn] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);
  // const [userdata, setUserData] = useState('');

  var API_DETAILS = APIServerRoot() + '/search/getuserdetails';

  const handleSubmit = (values) => {
    setSubmitting(true);
    setUserError(false);
    setUserFound(false);
    setUserIndexed(false);

    // Check for User in github
    let abortController = new AbortController();
    var post_data = `[{"Name":"` + values.gitusername.toLowerCase() + `"}]`;
    const doinitFetch = async () => {
      const initresponse = await axios({
        method: 'POST',
        url: APIServerRoot() + '/search/getgithubrepo',
        data: { post_data },
      });

      if (initresponse.data.url) {
        setUserFound(true);
      } else {
        setUserFound(false);
      }
    };
    doinitFetch();
    // Check for User in gitgenius.co

    post_data = `[{"Name":"` + values.gitusername.toLowerCase() + `"}]`;
    const doFetch = async () => {
      const axiosresponse = await axios({
        method: 'POST',
        url: API_DETAILS,
        data: { post_data },
      });
      if (axiosresponse.data != 'Not found') {
        setUserIndexed(true);
        var date = new Date(axiosresponse.data.escreated_at);
        var formattedDate = format(date, 'MMMM do, yyyy H:mma');
        setUserAddedOn(formattedDate);
        handleUserData(axiosresponse.data);
        // console.log('axiosresponse.data = ', axiosresponse.data);
      } else {
        setUserError(true);
      }
      // console.log('axiosresponse = ', axiosresponse);
    };
    doFetch();
    setSubmitting(false);

    return () => {
      // console.log('abort controller');
      abortController.abort();
    };
    // props.resetForm();
  };

  return (
    <Grid2
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      {isSubmitting && <LinearProgress />}
      <Paper>
        <Box m={0} p={3} sx={{ minWidth: '354px' }}>
          <Typography variant="h5">Input a User</Typography>
          <Formik
            initialValues={initialValue}
            validationSchema={YupValidation}
            onSubmit={handleSubmit}
          >
            {(props) => {
              const { gitusername } = props.values;
              return (
                <Form>
                  <TextField
                    label="User Name"
                    name="gitusername"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    value={gitusername}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="name" />}
                    error={props.errors.name && props.touched.name}
                    required
                  />
                  <div>{usererror}</div>
                  {usererror && !userfound && (
                    <Alert severity="error">
                      User not found —<strong>please revalidate</strong>
                    </Alert>
                  )}
                  {userfound && (
                    <Alert severity="success">
                      <strong>User Found in GitHub</strong>
                    </Alert>
                  )}
                  {usererror && userfound && !userindexed && (
                    <Alert severity="error">
                      <strong>User not found in GitGenius — </strong>
                      please adding in your account
                    </Alert>
                  )}
                  {userindexed && (
                    <Alert severity="success">
                      <strong>Added to GitGenius on {useraddedon}</strong>
                    </Alert>
                  )}
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    fullWidth
                    disabled={isSubmitting}
                  >
                    Lookup
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Paper>
    </Grid2>
  );
}

export default UserCheckForm;
