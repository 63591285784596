import { React, useEffect, useState } from 'react';
import { CardMedia, Grid2, Typography, Link, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import APIServerRoot from '../../../../modules/UIComponents/ApiEnvironment.jsx';
import axios from 'axios';
// import CardDetails from './RepoCardDetails.jsx';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  // color: theme.palette.text.secondary,
}));

const GridStyled = styled(Grid2)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  // padding: theme.spacing(1),
  // textAlign: 'center',
  // color: theme.palette.text.secondary,
}));

export default function RepoCard(repo) {
  const [repodetaildata, setRepoDetailData] = useState([]); // for Repo Details information

  const r = repo.repo;
  const uname = r.split('/')[0];
  const rname = r.split('/')[1];
  const image = 'https://avatars.githubusercontent.com/' + uname;

  const TColor = '#eee'; // Text Color
  const BTColor = '#48a7f2'; // Blue Text Color

  var API_DETAILS = APIServerRoot() + '/search/getrepodetails';

  useEffect(() => {
    document.title = 'GitGenius - Repo ' + r;
    let abortController = new AbortController();
    var post_data = `[{"Name":"` + r.toLowerCase() + `"}]`;
    const doFetch = async () => {
      const axiosresponse = await axios({
        method: 'POST',
        url: API_DETAILS,
        data: { post_data },
      });
      if (axiosresponse.data != 'Not found') {
        if (!axiosresponse.data.homepage) {
          axiosresponse.data.homepage =
            'http:///github.com/' + axiosresponse.data.full_name;
        }
        if (axiosresponse.data.subscribers_count) {
          axiosresponse.data.subscribers_count =
            axiosresponse.data.subscribers_count.toLocaleString();
        }
        if (axiosresponse.data.stargazers_count) {
          axiosresponse.data.stargazers_count =
            axiosresponse.data.stargazers_count.toLocaleString();
        }
      } else {
        console.log('Not found ', r.toLowerCase());
      }
      setRepoDetailData(axiosresponse.data);
    };
    doFetch();
    return () => {
      abortController.abort();
    };
  }, [r]);
  return (
    <GridStyled
      container
      sx={{
        alignItems: 'center',
        justify: 'center',
      }}
    >
      <Grid2 xs={4}>
        <Item elevation={0}>
          <CardMedia
            component="img"
            sx={{
              width: 50,
              height: 50,
              margin: 'auto',
            }}
            image={image}
            alt="Repository Logo"
          />
        </Item>
      </Grid2>
      <Grid2 xs={12}>
        <Item elevation={0}>
          <Link href={repodetaildata.homepage} target="_blank">
            <Typography variant="h6" color={BTColor}>
              {rname}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              by {uname}
            </Typography>
          </Link>
        </Item>
      </Grid2>
      <Grid2
        container
        size={12}
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Item elevation={0}>
          <Link href={'/repos/' + r} target="_blank">
            <Typography sx={{ color: { TColor } }}>
              Total Stargazers: {repodetaildata.stargazers_count}
            </Typography>
          </Link>
        </Item>
      </Grid2>
      <Grid2
        container
        size={12}
        spacing={0}
        direction="column"
        alignItems="center"
      >
        <Item elevation={0}>
          <Link href={'/repos/' + r} target="_blank">
            <Typography sx={{ color: { TColor } }}>
              Total Subscribers: {repodetaildata.subscribers_count}
            </Typography>
          </Link>
        </Item>
      </Grid2>
      <Grid2 xs={12}>
        <Item elevation={0}>
          <Typography variant="subtitle1" color="text.secondary">
            {repodetaildata.description}
          </Typography>
        </Item>
      </Grid2>
    </GridStyled>
  );
}
