import * as React from 'react';
// import { experimentalStyled as styled } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Button,
  Grid2,
  Link,
  // Paper,
  Card,
  CardContent,
  // CardMedia,
  Typography,
  Divider,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
// import BasicTable from './LookupDataTable.jsx';
import { format } from 'date-fns';

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body,
//   padding: theme.spacing(2),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));

// function createTableData(gitname, gitvalue) {
//   return { gitname, gitvalue };
// }

export function MediaCard({ userdata }) {
  const userlink = 'http://github.com/' + userdata.login;
  // console.log('userdata = ', userdata);
  const avatar = 'https://avatars.githubusercontent.com/u/' + userdata.id;
  var date = new Date(userdata.created_at);
  var created_at = format(date, 'MMMM do, yyyy H:mma');
  date = new Date(userdata.esupdated_at);
  var esupdated_at = format(date, 'MMMM do, yyyy H:mma');
  date = new Date(userdata.escreated_at);
  var escreated_at = format(date, 'MMMM do, yyyy H:mma');
  // console.log('date = ', created_at);
  return (
    <Box sx={{ display: 'inline-block' }}>
      <Card sx={{ display: 'flex' }}>
        <Avatar
          sx={{ width: 100, height: 100 }}
          alt={userdata.name}
          src={avatar}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent>
            <Typography variant="h5" component="div">
              <span style={{ fontWeight: 'bold' }}>Login: </span>{' '}
              {userdata.login}
            </Typography>
            <Typography variant="body" component="div">
              <span style={{ fontWeight: 'bold' }}>Name: </span> {userdata.name}
            </Typography>
            <Typography variant="body" component="div">
              <span style={{ fontWeight: 'bold' }}>EMail: </span>{' '}
              {userdata.email}
            </Typography>
            <Typography variant="body" component="div">
              <span style={{ fontWeight: 'bold' }}>Company: </span>{' '}
              {userdata.company}
            </Typography>
            <Typography variant="body" component="div">
              <span style={{ fontWeight: 'bold' }}>Bio: </span> {userdata.bio}
            </Typography>
            <Typography variant="body" component="div">
              <span style={{ fontWeight: 'bold' }}>Blog: </span>
              <Link href={userdata.blog} target="_blank">
                {userdata.blog} <OpenInNewIcon fontSize="small" />
              </Link>
            </Typography>
            <Typography variant="body" component="div">
              <span style={{ fontWeight: 'bold' }}>Location: </span>
              {userdata.location}
            </Typography>
            <Typography variant="body" component="div">
              <span style={{ fontWeight: 'bold' }}>Total Starred: </span>
              {userdata.esrepostarcount}
            </Typography>
            <Typography variant="body" component="div">
              <span style={{ fontWeight: 'bold' }}>Total Subscribed: </span>
              {userdata.esreposubcount}
            </Typography>
            <Typography variant="body" component="div">
              <span style={{ fontWeight: 'bold' }}>User/Org: </span>
              {userdata.type}
            </Typography>
            <Typography variant="body" component="div">
              <span style={{ fontWeight: 'bold' }}>Created on Github: </span>
              {created_at}
            </Typography>
            <Typography variant="body" component="div">
              <span style={{ fontWeight: 'bold' }}>Add to GitGenius on: </span>
              {escreated_at}
            </Typography>
            <Typography variant="body" component="div">
              <span style={{ fontWeight: 'bold' }}>Updated at: </span>
              {esupdated_at}
            </Typography>
            <Button size="small" href={userlink} target="_blank">
              View on GitHub
            </Button>
          </CardContent>
        </Box>
      </Card>
    </Box>
  );
}

export default function UserGetDetails({ userdata }) {
  // console.log('userdata in User = ', userdata);
  if (!userdata) {
    return;
  }
  const userarray = [];
  Object.keys(userdata).forEach((key) => {
    if (key != 'plan' && key != 'starredlist' && key != 'subscriptionslist') {
      // console.log('!key = ', key, userdata[key]);
      userarray.push({ name: key, value: userdata[key] });
    }
  });
  // const result = Object.keys(userdata).map((key) => ({ [key]: userdata[key] }));
  // console.log('const = ', result);
  // var date = new Date(userdata.created_at);
  // var created_at = format(date, 'MMMM do, yyyy H:mma');
  // date = new Date(userdata.esupdated_at);
  // var esupdated_at = format(date, 'MMMM do, yyyy H:mma');
  // date = new Date(userdata.escreated_at);
  // var escreated_at = format(date, 'MMMM do, yyyy H:mma');
  // // console.log('date = ', created_at);

  // const rows = [
  //   createTableData('Last Checked at', esupdated_at),
  //   createTableData('Created on GitGenius', escreated_at),
  //   createTableData('Created on GitHub', created_at),

  //   createTableData(
  //     'Starred Repo Count (Current & Previous)',
  //     userdata.esrepostarcount
  //   ),
  //   createTableData('Subscribed Repo Count', userdata.esreposubcount),
  // ];
  if (userarray.length > 0) {
    // console.log('arr name= ', userarray[0].name);
    return (
      <Grid2
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Divider />
        <MediaCard userdata={userdata} />
        <Divider />
      </Grid2>
    );
  }
}
