import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import APIServerRoot from '../UIComponents/ApiEnvironment.jsx';
import axios from 'axios';
import { Grid, Tooltip } from '@mui/material';
import RepoAccordian from './RepoAccordian.jsx';

export default function RepoAutoComplete() {
  const [libraryList, setLibraryRepoList] = useState([
    { label: 'blank', year: 1994 },
  ]);
  const [reposummary, setRepoSummary] = useState('');
  const [repoerror, setRepoError] = useState(false);
  const [repoindexed, setRepoIndexed] = useState(false);
  const [showdetail, setShowDetail] = useState(false);
  // const [currentApp, setCurrentApp] = useState({});

  useEffect(() => {
    let abortController = new AbortController();
    var API_DETAILS = APIServerRoot() + '/search/getrepoallnames';
    const doFetch = async () => {
      const axiosresponse = await axios({
        method: 'POST',
        url: API_DETAILS,
      });
      setLibraryRepoList(axiosresponse.data);
    };
    doFetch();

    return () => {
      // console.log('abort controller');
      abortController.abort();
    };
  }, []);

  function onChange(value) {
    // let abortController = new AbortController();
    // Test case - ohmyzsh/ohmyzsh
    // console.log('value = ', value);
    let repo = '';
    if (value) {
      console.log('here, value exists, ', value);
      setShowDetail(true);
      if (value && typeof value != 'string') {
        repo =
          value.RepoUser.toLowerCase() + '/' + value.RepoName.toLowerCase();
      } else if (value && typeof value == 'string') {
        if (value.includes('https://github.com/')) {
          let words = value.split('/');
          repo = words[3].toLowerCase() + '/' + words[4].toLowerCase();
        } else {
          repo = value.toLowerCase();
        }
      }
      // console.log('here, repo = ', repo);
      let post_data = `[{"Name":"` + repo + `"}]`;
      const doinitFetch = async () => {
        const initresponse = await axios({
          method: 'POST',
          url: APIServerRoot() + '/search/getgithubrepo',
          data: { post_data },
        });
        if (!initresponse.data.full_name) {
          setRepoError(true);
          setRepoIndexed(false);
          setRepoSummary('Not Found on Github or GitGenius');
        } else {
          // Consider use case where repo has new name
          // let repolowercase = initresponse.data.full_name.toLowerCase();
          // if (repo == repolowercase) {  escreated_at

          if (!initresponse.data.escreated_at) {
            // and not in repolist
            setRepoIndexed(false);
          } else {
            setRepoIndexed(true);
          }
          setRepoSummary(initresponse.data);
          setRepoError(false);
          // console.log('setRepoSummary');
        }
      };
      doinitFetch();
      // console.log('here - did fetch');
    } else {
      setShowDetail(false);
    }
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Autocomplete
        disablePortal
        freeSolo
        clearOnEscape
        id="autocomplete-repos"
        getOptionLabel={(option) =>
          option.RepoUser ? option.RepoUser + '/' + option.RepoName : option
        }
        // getOptionLabel={(option) => option.RepoUser + '/' + option.RepoName}
        options={libraryList}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <Tooltip
            title="Get details on a Repository - choose from a list of currently 
            analyzed repositories, or enter your own (and copy/paste repo url)."
            placement="left"
            arrow
          >
            <TextField
              {...params}
              label="Select a Repository"
              variant="standard"
            />
          </Tooltip>
        )}
        onChange={(event, values) => onChange(values)}
      />
      {showdetail && (
        <RepoAccordian
          repoinfo={{
            reposummary: reposummary,
            repoerror: repoerror,
            repoindexed: repoindexed,
          }}
        />
      )}
    </Grid>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
// const top100Films = [
//   { label: 'The Shawshank Redemption', year: 1994 },
//   { label: 'The Godfather', year: 1972 },
// ];
