import React, { useState } from 'react';
import axios from 'axios';
import GridItem from '../../../modules/Grid/GridItem.jsx';
import CustomInput from '../../../modules/CustomInput/CustomInput.jsx';
import Button from '../../../modules/CustomButtons/Button.jsx';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  Grid2,
  Slide,
} from '@mui/material';
import APIServerRoot from '../../../modules/UIComponents/ApiEnvironment.jsx';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SectionContact = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const resetForm = () => {
    document.getElementById('contact-form').reset();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const API_ROOT = APIServerRoot() + '/sendmail';

    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;
    let message = document.getElementById('message').value;
    message = 'Contact Form: ' + message;

    axios({
      method: 'POST',
      url: API_ROOT,
      data: {
        name,
        email,
        message,
      },
    })
      .then((response) => {
        if (response.data === 'success') {
          window.gtag('event', 'Page Read', {
            event_category: 'Form Filled',
            event_label: 'Contact formed filled out by ' + email,
          });
          setOpen(true);
          resetForm();
        } else if (response.data === 'error') {
          alert('Message failed to send.');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Card
      elevation={0}
      style={{ background: 'none' }}
      sx={{ minWidth: '100%' }}
    >
      <GridItem
        // direction="column"
        justifyContent="center"
        alignItems="center"
        xs={12}
        sm={12}
      >
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {'Message Sent'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Thank you taking the time to send us a message, we will follow up
              shortly!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <form
          id="contact-form"
          onSubmit={handleSubmit}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Grid2 justifyContent="center" alignItems="center" sm={6} lg={4}>
            <GridItem>
              <CustomInput
                labelText="Your Name"
                id="name"
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                labelText="Your Email"
                id="email"
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                labelText="Your Message"
                id="message"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  multiline: true,
                  rows: 5,
                }}
              />
            </GridItem>
            <GridItem>
              <Button color="primary" type="submit">
                Send Message
              </Button>
            </GridItem>
          </Grid2>
        </form>
      </GridItem>
    </Card>
  );
};

export default SectionContact;
